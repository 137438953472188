<script>
  import Icon from "./Icon.svelte";

  export let width;
  export let height;
  export let fill;
  export let shadow = true;
</script>

<Icon {width} {height} {shadow}>
  <svg
    {width}
    {height}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 7L0.5 14V0L11.5 7ZM7.77 7L2.5 3.64V10.36L7.77 7Z"
      {fill}
      fill-opacity="0.54"
    />
  </svg>
</Icon>
