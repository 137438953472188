<script>
  import Icon from "./Icon.svelte";

  export let width = 13;
  export let height = 17;
  export let fill = "black";
  export let shadow = true;
</script>

<Icon {width} {height} {shadow}>
  <svg
    {width}
    {height}
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.47954 2.08162C3.47954 2.91223 4.15913 3.59182 4.98974 3.59182C5.82035 3.59182 6.49995 2.91223 6.49995 2.08162C6.49995 1.251 5.82035 0.571411 4.98974 0.571411C4.15913 0.571411 3.47954 1.251 3.47954 2.08162ZM6.95308 10.7653L7.70818 14.0877L12.9939 13.0306L12.6919 11.5204L8.99186 12.2755L7.78369 6.15917L9.14288 6.68774V9.25508H10.6531V5.7061L6.72655 4.04488C6.61328 4.04488 6.5189 4.026 6.42451 4.00713C6.33012 3.98825 6.23573 3.96937 6.12247 3.96937C5.5939 3.96937 5.14083 4.27141 4.83879 4.72447L4.08369 5.93264C3.47961 6.98978 2.27145 7.74488 0.836752 7.74488V9.25508C2.49798 9.25508 4.00818 8.49998 4.98981 7.36733L5.44288 9.63264L3.85716 11.1428V16.8061H5.36736V12.2755L6.95308 10.7653Z"
      {fill}
      fill-opacity="0.54"
    />
  </svg>
</Icon>
